<template>
  <div>
    <!-- <div class="split overridesplitleft left">
      <img
        src="@/assets/images/logo/sc-logo.png"
        alt="asd"
        style="width:400px;height:250px;"
      >
    </div> -->

    <div class="d-flex flex-column justify-content-center split overridesplitright right">
      <div
        class="screensize"
        style="margin:auto;"
      >
        <!-- email{{ getEmail }} -->
        <div class="d-flex col-lg-12 col-sm-12 col-md-12 mb-2">
          <span style="font-size:36px; color:black;"><strong>{{ $t ("Update your password") }}</strong></span>
        <!-- <span style="font-size:1.5vw; color:black;"><strong>Update your password</strong></span> -->
        </div>
        <div class="d-flex col-lg-12 col-sm-12 col-md-12 mb-3">
          <span style="font-size: clamp(18px,5vw,24px);"><strong>{{ obsecure }}</strong></span>
        <!-- <span style="font-size:1.2vw; "><strong>sarahpuzi@gmail.com</strong></span> -->
        </div>
        <validation-observer ref="validateReset">
          <div class="form-group col-lg-12 col-sm-12 col-md-12 mb-2">
            <validation-provider
              #default="{ errors }"
              :name="$t('New Password')"
              rules="required"
            >
              <input
                id="new_password"
                v-model="newPassword"
                :type="[showPassword ? 'text' : 'password']"
                class="form-control form-control-lg"
                :placeholder="$t('New Password')"
                @keyup="match()"
              >
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <i
              class="fa field-icon"
              :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
              aria-hidden="true"
              @click="showPassword = !showPassword"
            />
          </div>

          <div class="form-group col-lg-12 col-sm-12 col-md-12 mb-3">
            <validation-provider
              #default="{ errors }"
              :name="$t('Re-type New Password')"
              rules="required"
            >
              <input
                id="confirm_password"
                v-model="retypePassword"
                :type="[confirmPassword ? 'text' : 'password']"
                class="form-control form-control-lg"
                :placeholder="$t('Re-type New Password')"
                @keyup="match()"
              >
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <i
              class="fa field-icon"
              :class="[confirmPassword ? 'fa-eye' : 'fa-eye-slash']"
              aria-hidden="true"
              @click="confirmPassword = !confirmPassword"
            />
            <div
              id="message3"
              style="color: red; font-size: 11px;"
            />
          </div>

          <div class="d-flex col-12 justify-content-center mb-2 no-gutters">
            <!-- <router-link
              :to="{ name: 'login' }"
              class="col-12"
            > -->
            <button
              type="submit"
              class="btn btn-primary"
              style="width: 100%;"
              @click="changePassword()"
            >
              {{ $t ("Update Password") }}
            </button>
            <!-- </router-link> -->
          </div>

        </validation-observer></div>
    </div>

  </div>
</template>

<style scoped>

    .split {
      height: 100%;
      width:50%;
      position: fixed;
      z-index: 1;
      top: 0;
      overflow-x: hidden;
      padding-top: 20px;
    }

    .left {
   background: url('~@/assets/images/background/login.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      /* background-image: url('~@/assets/images/background/login.png');
      height: 100%; */
      background-position: center;
    }
    .right {
      right: 0;
      background-color: white;
    }

    .centered {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 100px;
        text-align: center;
    }

    .pass_show{position: relative}

    .pass_show .ptxt {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 1;
      margin-top: -10px;
      cursor: pointer;
      transition: .3s ease all;
    }

    .pass_show .ptxt:hover{color: #333333;}

    .field-icon {
      float: right;
      margin-left: -25px;
      margin-right: 10px;
      margin-top: -30px;
      position: relative;
      z-index: 2;
    }

    .centered img {
      width: 150px;
      border-radius: 50%;
    }
    .screensize{
      width: 48%;
    }

    /* Split screen */
    @media screen and (max-width: 1100px) {
        .overridesplitright{
          width: 100% !important;
        }

        .overridesplitleft{
          display: none !important;
        }
    }
    /* End Split screen */
    @media screen and (max-width: 2000px) {
        .screensize{
          width: 48%;
        }

        .code{
          font-size:18px;
          height: 72px;
          margin-left: 3%;
          width: 10px;
        }
    }
    @media screen and (max-width: 1700px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1400px) {
      .screensize{
        width: 66%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1200px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1100px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 400px) {
      .screensize{
        width: 86%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 300px) {
      .screensize{
        width: 90%;
      }

      .code{
        font-size:10px;
        height: 52px;
        margin-left: 2%;
        width: 10px;
      }
    }

</style>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from "@/component/saj-toastification.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data(){
    return {
      showPassword: false,
      confirmPassword: false,
      email: "SarahPuzi@gmail.com",
      obsecure: "",
      newPassword: "",
      retypePassword: "",
      required,
    }
  },
  beforeMount() {
    this.getEmail = this.$route.params.getEmail
  },
  mounted(){
    this.obsecureEmail()
  },
  methods: {

    match() {
      document.getElementById("new_password").style.borderColor = ""
      document.getElementById("confirm_password").style.borderColor = ""
      document.getElementById('message3').innerHTML = ""

      if (document.getElementById('new_password').value === document.getElementById('confirm_password').value) {
        document.getElementById('message3').style.color = 'green'
        document.getElementById('message3').innerHTML = 'Matching'
      } else if (document.getElementById('new_password').value !== document.getElementById('confirm_password').value){
        document.getElementById('message3').style.color = 'red'
        document.getElementById('message3').innerHTML = 'Not matching'
        document.getElementById("new_password").style.borderColor = "red"
        document.getElementById("confirm_password").style.borderColor = "red"
      } else {
        document.getElementById('message3').innerHTML = ""
        document.getElementById("new_password").style.borderColor = ""
        document.getElementById("confirm_password").style.borderColor = ""
      }
    },
    changePassword(){
      this.$refs.validateReset.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('email', this.getEmail)
          data.append('password', this.newPassword)
          data.append('password_confirmation', this.retypePassword)
          // data.forEach((value, key) => {
          //   console.log(`${key} ${value}`)
          // })
          this.$axios.post(`${this.$baseUrl}/password/pinnumber/reset`, data).then(response => {
            // console.log("berjaya", response)
            // console.log(response.data.status)
            if (response.data.success === true){
              this.$router.push({ name: 'login' })
            }
            // if (response.data.data.password)
          }).catch(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('The password confirmation does not match')}.`,
                  icon: "XIcon",
                  iconColor: 'white',
                  iconBg: '#e80202',
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'error',
              },
            )
          })
        }
      })
    },

    obsecureEmail(){
      const [name, domain] = this.getEmail.split('@')
      // console.log(name.length)
      this.obsecure = `${name[0]}${new Array(name.length - 1).join('*')}${name[name.length - 1]}@${domain}`
      return this.obsecure
    },
  },

}
</script>
